<template>
    <div class="dialog-main">
        <div class="pay-title">
            {{goodsName}}
        </div>

        <div style="font-size: 13px;">
            <div v-if="showJxfDiscount" style="font-size: 12px;text-align: left;color: #7a69be;padding: 0 0px;">
                开服首日2.5折优惠，剩余时间：
                <van-count-down style="display: inline;color: #7a69be;font-size: 12px;"
                    ref="countDown"
                    :time="leaveTime"
                    :auto-start="true"
                    @finish="finish"/>
            </div>

            <div class="goods-item">
                <span>支付价格</span>
                <div>
                    <span v-if="showOriginalPrice" style="color: #9e9e9e;text-decoration: line-through;margin-right: 5px;">
                        {{ payOriginalPrice }}
                    </span>
                    <span style="font-weight: bold;color: #7a69be">{{payPrice}}</span>
                </div>

            </div>
            <div class="goods-item">
                <span>优惠 / 折扣</span>
                <span>{{payDiscount}}</span>
            </div>
        </div>

        <div class="pay-page-body">
            <div style="color: #333333;font-size: 12px;margin-top: 15px;text-align: left">请选择支付方式</div>
            <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 10px;">
                <div v-if="wxPayEnable === 1" @click="selectPayment('wx')" class="payment-item" style="" :class="selectedPay === 'wx' && 'payment-active'">
                    <img src="../assets/pay_wx.png" style="width: 20px;height: 20px;margin-left: 15px;">
                    <span style="margin-left: 10px;display: block;font-size: 14px;">微信</span>
                    <img v-show="selectedPay === 'wx'" class="img-jb" src="../assets/selected_jb.png">
                </div>
                <div v-if="zfbPayEnable === 1" @click="selectPayment('ali')" class="payment-item" style="" :class="selectedPay === 'ali' && 'payment-active'">
                    <img src="../assets/pay_zfb.png" style="width: 20px;height: 20px;margin-left: 15px;">
                    <span style="margin-left: 10px;display: block;font-size: 14px;">支付宝</span>
                    <img v-show="selectedPay === 'ali'" class="img-jb" src="../assets/selected_jb.png">
                </div>
            </div>

            <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 15px;">
                <div v-if="ptbPayEnable === 1" @click="selectPayment('ptb')" class="payment-item" style="" :class="selectedPay === 'ptb' && 'payment-active'">
                    <img src="../assets/pay_ptb.png" style="width: 20px;height: 20px;margin-left: 15px;">
                    <div style="margin-left: 10px;text-align: left">
                        <span style="display: block;font-size: 14px;">星元</span>
                        <span style="display: block;font-size: 11px;color: #888888">￥{{ptb}}</span>
                    </div>
                    <img v-show="selectedPay === 'ptb'" class="img-jb" src="../assets/selected_jb.png">
                </div>
                <div v-if="djqPayEnable === 1" @click="selectPayment('djq')" class="payment-item" style="" :class="selectedPay === 'djq' && 'payment-active'">
                    <img src="../assets/pay_djq.png" style="width: 20px;height: 20px;margin-left: 15px;">
                    <div style="margin-left: 10px;text-align: left">
                        <span style="display: block;font-size: 14px;">代金券</span>
                        <span style="display: block;font-size: 11px;color: #888888">￥{{djq}}</span>
                    </div>
                    <img v-show="selectedPay === 'djq'" class="img-jb" src="../assets/selected_jb.png">
                </div>
            </div>

        </div>

        <div class="pay-page-footer">
            <div @click="close" style="color: #888888;font-size: 13px;cursor: pointer;">取消支付</div>
            <div v-show="scanButtonShow" @click="submit(true)" class="text-button">扫码支付</div>
            <div @click="submit(false)" class="pay-button">立即支付</div>
        </div>

        <myloading v-show="showLoading"></myloading>
    </div>
</template>

<script>
    import {request} from "@/api/request";
    import myloading from '@/components/myloading'
    import {Toast, Dialog} from "vant";
    import sdkNotice from "@/api/sdk-notice";

    export default {
        name: "SdkRecharge",
        components: {
            myloading
        },
        data: function () {
            return {
                goodsName: '--',
                showLoading: false,
                showOriginalPrice: true,
                selectedPay: '',
                payUrl: '',
                payOriginalPrice: '-',
                payPrice: '-',
                payDiscount: '- / -',
                ptb: '0',
                djq: '0',
                money: '',
                order: {},
                wxPayEnable: 0,
                zfbPayEnable: 0,
                wxScanPayEnable: 0,
                zfbScanPayEnable: 0,
                djqPayEnable: 0,
                ptbPayEnable: 0,
                scanButtonShow: false,
                from: '',

                copyPayPrice: '',
                copyPayDiscount: '',
                copyShowOriginalPrice: true,

                showJxfDiscount: false,
                leaveTime: 0,
            }
        },
        mounted() {
            this.from = localStorage.getItem("from");
        },
        methods: {
            finish() {
                this.showJxfDiscount = false;
            },
            initOrder(order) {
                this.showOriginalPrice = true;
                this.scanButtonShow = false;
                this.selectedPay = '';
                this.queryBalance(order.gameId);
                this.goodsName = order.goodsName;
                this.payPrice = "￥" + (order.money / 100).toFixed(2);
                this.payOriginalPrice = this.payPrice;
                this.money = order.money;
                this.order = order;

                let options = {
                    url:"/h5/checkUserLM",
                    data: {
                        gameId: this.order.gameId,
                        money: this.money,
                        serverId: this.order.serverId,
                    }
                };
                request(options).then(res => {
                    if (res.data.success) {
                        let discount =res.data.data.discount;
                        if (parseFloat(discount) === 1) {
                            this.showOriginalPrice = false;
                            this.copyShowOriginalPrice = false;
                        }
                        else {
                            this.showOriginalPrice = true;
                            this.copyShowOriginalPrice = true;
                        }

                        this.payDiscount = "￥" + res.data.data.preferenceMoney + ' / ' + res.data.data.discount ;
                        this.payPrice = "￥" + res.data.data.orderMoney;

                        this.copyPayDiscount = this.payDiscount;
                        this.copyPayPrice = this.payPrice;

                        this.wxPayEnable = res.data.data.wxPayEnable;
                        this.zfbPayEnable = res.data.data.zfbPayEnable;
                        this.wxScanPayEnable = res.data.data.wxScanPayEnable;
                        this.zfbScanPayEnable = res.data.data.zfbScanPayEnable;
                        this.djqPayEnable = res.data.data.djqPayEnable;
                        this.ptbPayEnable = res.data.data.ptbPayEnable;

                        this.leaveTime =  parseInt(res.data.data.leaveTime) * 1000;
                        if (this.leaveTime !== '0') {//当前有2.5折活动
                            this.showJxfDiscount = true;
                        }
                    }
                    else {
                        Toast(res.data.error.message);
                        this.close();
                    }
                });
            },
            queryBalance(gameId) {
                let options = {
                    url:"/h5/getBalance",
                    data: {
                        gameId: gameId
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        let data = res.data.data;
                        this.ptb = data.ptb;
                        this.djq = data.djq;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            close() {
                this.$parent.$parent.close('payJsf')
            },
            selectPayment(payment) {
                this.selectedPay = payment;

                if (payment === 'djq') {
                    this.payDiscount = '无优惠';
                    this.payPrice = this.payOriginalPrice;
                    this.showOriginalPrice = false;
                }
                else {
                    this.payDiscount = this.copyPayDiscount;
                    this.payPrice = this.copyPayPrice;
                    this.showOriginalPrice = this.copyShowOriginalPrice;
                }

                if (!this.isMobile())
                    return;

                if (payment === 'wx') {
                    this.scanButtonShow = this.wxScanPayEnable === 1;
                }
                else if (payment === 'ali') {
                    this.scanButtonShow = this.zfbScanPayEnable === 1;
                }
                else {
                    this.scanButtonShow = false;
                }
            },
            submit(isScan) {
                if (!this.selectedPay) {
                    Toast('请选择支付方式');
                    return ;
                }
                if (!isScan) {
                    isScan = !this.isMobile();//如果是PC端则设置为扫码支付
                }
                this.showLoading = true;
                let payType;
                if (this.selectedPay === 'wx') {
                    payType = isScan ? 22 : 21;
                }
                else if (this.selectedPay === 'ali') {
                    payType = isScan ? 32 : 31;
                }
                else if (this.selectedPay === 'ptb') {
                    payType = 11;
                }
                else if (this.selectedPay === 'djq') {
                    payType = 12;
                }

                let options = {
                    url: "/h5/preOrderLM",
                    data: {
                        paymentType: payType,
                        money: this.money,
                        goodsName: this.goodsName,
                        gameId: this.order.gameId,
                        accountId: this.order.userId, //accountId
                        cpOrderId: this.order.cpOrderId,
                        roleId: this.order.roleId,
                        roleName: this.order.roleName,
                        serverId: this.order.serverId,
                        serverName: this.order.serverName,
                        time: this.order.time,
                        sign: this.order.sign
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.close();
                        if (payType > 20) {
                            this.payUrl = res.data.data.payString;
                            let orderId = res.data.data.orderId;
                            if (this.from === 'android') {
                                window.androidJs.openUrl(this.payUrl);
                                setTimeout(() => {
                                    window.androidJs.openUrl('https://notexistpage404.com');
                                }, 10000);

                            }
                            else {
                                this.$parent.$parent.openPayUrl(this.payUrl, payType, this.payPrice, isScan, orderId);
                            }

                        }
                        else {
                            Toast('支付成功');
                        }
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            isMobile() {
                let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
                return flag;
            },
        }
    }
</script>

<style scoped>
    .dialog-main {
        padding: 0 25px;
        user-select: none;
    }

    .pay-title {
        line-height: 40px;
        font-size: 14px;
        font-weight: bold;
        color: #555555;
    }
    .goods-item {
        height: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #666666;
    }
    .pay-page-body {
        color: #888888;
    }

    .pay-page-footer {
        height: 50px;
        margin: 15px auto 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .text-button {
        font-size: 13px;
        color: #7a69be;
        font-weight: bold;
    }
    .pay-button {
        background: #7a69be;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 35px;
        font-size: 13px;
        font-weight: bold;
        border-radius: 35px;
        cursor: pointer;
        user-select: none;
    }
    .payment-item {
        width: 120px;
        height: 40px;
        outline: 1px solid #cccccc;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #666666;
        font-size: 13px;
        position: relative;
        cursor: pointer;
    }
    .payment-active {
        outline: 2px solid #7a69be;
        color: #7a69be;
        font-weight: bold;
    }
    .img-jb {
        position: absolute;
        left: -2px;
        top: -2px;
        width: 20px;
        height: 20px;
    }
</style>