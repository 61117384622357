console.log('process.env',process.env)
const serverList = [
  {
    BASEURL: 'http://192.168.1.26:8304',
  },
  {
    BASEURL: 'https://game.daoyigame.com/api',
  }
];
let serverArr = serverList[1]
export default serverArr
