<template>
    <div class="sdk-page">
        <div class="sdk-nav-bar">
            <div @click="back" style="width: 40px;height: 40px;display: flex;align-items: center;justify-content: center; position: absolute;">
                <van-icon name="arrow-left" size="20" color="#888888"/>
            </div>
            <div style="font-size: 16px;">
                修改密码
            </div>
            <van-icon @click="close" name="cross" color="#d0d0d0" size="20" class="close-x"/>
        </div>

        <div class="sdk-password-body">
            
            <van-field  maxlength="15" id="password1" type="password" placeholder="原密码" class="input-view" style="margin-top: 10px;" clearable v-model="password_1" />
            
            <van-field  maxlength="15" id="password2" type="password" placeholder="新密码(6-15位数字字母特殊符号)" class="input-view" style="margin-top: 10px;" clearable v-model="password_2" />
            
            <van-field  maxlength="15" id="password3" type="password" placeholder="确认新密码" class="input-view" style="margin-top: 10px;" clearable v-model="password_3" />
        </div>

        <div class="sdk-page-footer">
            <div class="sdk-submit-button" @click="submit">立即修改</div>
        </div>

        <myloading v-show="showLoading"></myloading>
    </div>
</template>

<script>
    import {sdkRequest} from "@/api/request";
    import myloading from '@/components/myloading'
    import Toast from "vant/lib/toast";
    import crypto from '@/utils/crypto.js';
    import sdkNotice from '@/api/sdk-notice'
    import '../../css/sdkcommon.css'


    export default {
        name: "SdkPassword",
        components: {
            myloading
        },
        data: function () {
            return {
                showLoading: false,
                from: '',
                password_1: '',
                password_2: '',
                password_3: '',
                passwordRegexp: /^[a-zA-Z0-9~!@#$%^&*()_+.-=]{6,15}$/,
            }
        },
        mounted() {
            this.from = localStorage.getItem('from');
            this.addInputBlur();
        },
        methods: {
            back() {
                this.$router.back();
            },
            close() {
                sdkNotice.close();
            },
            submit() {
                let ok = this.checkForm();
                if (ok !== 'ok') {
                    Toast(ok);
                    return ;
                }

                this.showLoading = true;
                let options = {
                    url:"/sdk/h5/password",
                    data: {
                        newPassword: crypto.encrypt(this.password_2),
                        oldPassword: crypto.encrypt(this.password_1)
                    }
                };

                sdkRequest(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        Toast('密码修改成功,请重新登录');
                        setTimeout(() => {
                            this.exit();
                            localStorage.clear();
                        }, 1500)
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            exit() {
                sdkNotice.logout();
            },
            checkForm() {
                if (!this.password_1) {
                    return "请输入原密码";
                }
                if (!this.password_2) {
                    return "请输入新密码";
                }
                if (!this.password_3) {
                    return "请再次输入新密码";
                }
                if(!this.passwordRegexp.test(this.password_1)) {
                    return "原密码为6-15位数字字母特殊符号";
                }
                if(!this.passwordRegexp.test(this.password_2)) {
                    return "新密码为6-15位数字字母特殊符号";
                }
                if(this.password_2 !== this.password_3) {
                    return "两次密码输入不一致，请重新输入";
                }
                if(this.password_1 === this.password_2) {
                    //return "新密码不能和旧密码相同";
                }
                return "ok";
            },
            addInputBlur() {
                let isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // 检测是否为ios
                if(!isIOS) {
                    console.log('不是iOS，取消blur事件');
                    return ;
                }
                let password1 = document.getElementById('password1');
                password1.removeEventListener('blur', function(){ window.scrollTo(0,0)});
                password1.addEventListener('blur', function(){window.scrollTo(0,0)}, false);

                let password2 = document.getElementById('password2');
                password2.removeEventListener('blur', function(){ window.scrollTo(0,0)});
                password2.addEventListener('blur', function(){window.scrollTo(0,0)}, false);

                let password3 = document.getElementById('password3');
                password3.removeEventListener('blur', function(){ window.scrollTo(0,0)});
                password3.addEventListener('blur', function(){window.scrollTo(0,0)}, false);

            },
        }
    }
</script>

<style scoped>
    .sdk-page-footer {
        width: 80%;
        height: 50px;
        margin: 0 auto;
        display: flex;
        align-items: center;
    }

    .sdk-submit-button {
        background: #7a69be;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 35px;
        font-size: 13px;
        font-weight: bold;
        border-radius: 3px;
        cursor: pointer;
        user-select: none;
    }

    .sdk-password-body {
        width: 80%;
        height: calc(100vh - 110px);
        margin: 0 auto;
    }

</style>