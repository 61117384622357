<template>
    <div class="main-view">
        <home-nav></home-nav>
        <gzh-code-view></gzh-code-view>

        <img src="../../assets/logo/bg.png" style="width: 100%;position: absolute;top: 0;left: 0;z-index: -1" :style="{'height': height}"/>

        <div style="width: 100%;top: 400px;margin-top: 320px">
            <div style="display: flex;align-items: center;max-width: 1200px;margin: 0 auto;">
                <div class="code-view">
                    <img src="../../assets/demo_1.png" style="width: 105px;height: 105px;">
                    <span style="font-size: 10px;color: #666666;margin-top: 10px;">扫码下载app</span>
                </div>

                <div style="margin-left: 50px;">
                    <div class="download-button">
                        <img src="../../assets/android.png" style="width: 20px;height: 20px;margin-right: 10px;">
                        安卓版下载
                    </div>
                    <div class="download-button" style="margin-top: 20px">
                        <img src="../../assets/iOS.png" style="width: 20px;height: 20px;margin-right: 10px;">
                        苹果版下载
                    </div>
                </div>
            </div>
        </div>

        <div style="display: flex;width: 100%;max-width: 1200px;margin: 80px auto 0; justify-content: space-between;">
            <div class="main-recommend-view" @click="toDetail(leftGame.gameId)">
                <div style="display: flex;align-items: center;">
                    <img :src="leftGame.icon" style="width: 70px;height: 70px;border-radius: 10px;">
                    <div style="display: flex;height: 55px; margin-left: 15px;flex-direction: column;justify-content: space-between">
                        <span style="font-size: 20px;font-weight: bold;color: #333333">{{leftGame.gameName}}</span>
                        <div class="game-tag-view">
                            <span v-for="tag in leftGame.tagList">{{tag}}</span>
                        </div>
                    </div>
                </div>
                <van-image :src="leftGame.gameImage" style="width: 100%;height: 230px;border-radius: 10px;margin-top: 10px;"/>
                <div style="color: #aaaaaa;text-align: left;margin-top: 20px">新游上线</div>
            </div>

            <div  class="main-recommend-view" @click="toDetail(rightGame.gameId)">
                <div style="display: flex;align-items: center;">
                    <img :src="rightGame.icon" style="width: 70px;height: 70px;border-radius: 10px;">
                    <div style="display: flex;height: 55px; margin-left: 15px;flex-direction: column;justify-content: space-between">
                        <span style="font-size: 20px;font-weight: bold;color: #333333">{{rightGame.gameName}}</span>
                        <div class="game-tag-view">
                            <span v-for="tag in rightGame.tagList">{{tag}}</span>
                        </div>
                    </div>
                </div>
                <img :src="rightGame.gameImage" style="width: 100%;height: 230px;border-radius: 10px;margin-top: 10px;" fit="cover"/>
                <div style="color: #aaaaaa;text-align: left;margin-top: 20px">激情PK，万人在线</div>
            </div>
        </div>

        <div style="display: flex;width: 100%;max-width: 1200px;margin: 50px auto 0;justify-content: space-between;">
            <div class="top-view">
                <div class="top-view-head">
                    <div class="top-view-name_pre"></div>
                    <div class="top-vew-name">
                        热游榜
                    </div>
                </div>
                <div v-for="(item,index) in hotGameList" @click="toDetail(item.gameId)" class="top-game-item">
                    <img :src="item.icon" style="width: 110px;height: 110px;border-radius: 20px;">
                    <div style="display: flex;height: 90px; margin-left: 15px;flex-direction: column;justify-content: space-between">
                        <span class="game-name-one-line">{{item.gameName}}</span>
                        <div class="game-tag-view">
                            <span v-for="tag in item.tagList">{{tag}}</span>
                        </div>
                        <div style="display: flex;justify-content: flex-start">
                            <van-icon name="star" color="#f55050"/>
                            <van-icon name="star" color="#f55050"/>
                            <van-icon name="star" color="#f55050"/>
                            <van-icon name="star" color="#f55050"/>
                            <van-icon v-if="index < 3 " name="star" color="#f55050"/>
                            <van-icon v-if="index >= 3 " name="star" color="#dddddd"/>
                        </div>
                    </div>
                </div>

            </div>
            <div class="top-view">
                <div class="top-view-head">
                    <div class="top-view-name_pre"></div>
                    <div class="top-vew-name">
                        新游上线
                    </div>
                </div>
                <div v-for="(item,index) in newGameList" @click="toDetail(item.gameId)" class="top-game-item">
                    <img :src="item.icon" style="width: 110px;height: 110px;border-radius: 20px;">
                    <div style="display: flex;height: 90px; margin-left: 15px;flex-direction: column;justify-content: space-between">
                        <span class="game-name-one-line">{{item.gameName}}</span>
                        <div class="game-tag-view">
                            <span v-for="tag in item.tagList">{{tag}}</span>
                        </div>
                        <div style="display: flex;justify-content: flex-start">
                            <van-icon name="star" color="#f55050"/>
                            <van-icon name="star" color="#f55050"/>
                            <van-icon name="star" color="#f55050"/>
                            <van-icon name="star" color="#f55050"/>
                            <van-icon v-if="index < 3 " name="star" color="#f55050"/>
                            <van-icon v-if="index >= 3 " name="star" color="#dddddd"/>
                        </div>
                    </div>
                </div>

            </div>

            <div class="top-view">
                <div class="top-view-head">
                    <div class="top-view-name_pre"></div>
                    <div class="top-vew-name">
                        玩家推荐
                    </div>
                </div>
                <div v-for="(item,index) in recommendGameList" @click="toDetail(item.gameId)" class="top-game-item">
                    <img :src="item.icon" style="width: 110px;height: 110px;border-radius: 20px;">
                    <div style="display: flex;height: 90px; margin-left: 15px;flex-direction: column;justify-content: space-between">
                        <span class="game-name-one-line">{{item.gameName}}</span>
                        <div class="game-tag-view">
                            <span v-for="tag in item.tagList">{{tag}}</span>
                        </div>
                        <div style="display: flex;justify-content: flex-start">
                            <van-icon name="star" color="#f55050"/>
                            <van-icon name="star" color="#f55050"/>
                            <van-icon name="star" color="#f55050"/>
                            <van-icon name="star" color="#f55050"/>
                            <van-icon v-if="index < 3 " name="star" color="#f55050"/>
                            <van-icon v-if="index >= 3 " name="star" color="#dddddd"/>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div style="display: flex;width: 100%;max-width: 1200px;margin: 50px auto 50px;justify-content: space-between;">
            <div style="background-color: #ffffff;border-radius: 10px;cursor: pointer;width: 100%;">
                <div class="top-view-head">
                    <div class="top-view-name_pre"></div>
                    <div class="top-vew-name">
                        精品游戏
                    </div>
                </div>

                <div style="display: flex;align-items: center;justify-content: space-between;padding: 0 30px 20px;margin-top: 10px;">
                    <div v-for="item in bottomGameList" @click="toDetail(item.gameId)" class="hor-game-view">
                        <img :src="item.gameImage" style="width: 100%;height: 200px;border-radius: 10px;">
                        <span class="game-name-one-line" style="margin-top: 10px;">{{item.gameName}}</span>
                        <div class="game-tag-view" style="margin-top: 10px;">
                            <span v-for="tag in item.tagList">{{tag}}</span>
                        </div>
                    </div>
                </div>


            </div>
        </div>



        <home-footer></home-footer>
    </div>
</template>

<script>
    import {request} from "@/api/request";
    import homeNav from "@/components/HomeNav";
    import homeFooter from "@/components/HomeFooter";
    import gzhCodeView from "@/components/GzhCodeView";
    import demo2Img from "@/assets/demo_2.png";
    import {Toast} from "vant";

    export default {
        name: "homeMain",
        components: {
            homeNav,
            homeFooter,
            gzhCodeView,
        },
        data() {
            return {
                step: 1,
                demo2Img,
                pageGameInfo: {},
                leftGame: {},
                rightGame: {},
                hotGameList: [],
                newGameList: [],
                recommendGameList: [],
                bottomGameList: [],
                height: '0px',
            }
        },
        mounted() {
            this.height = (window.innerHeight) + 'px';
            this.initPage();
        },
        methods: {
            initPage() {
                this.showLoading = true;
                let options = {
                    url:"/web/getHomeGame",
                    data: {}
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        let data = res.data.data;
                        this.leftGame = data.leftInfo;
                        this.rightGame = data.rightInfo;
                        this.hotGameList = data.hotList;
                        this.newGameList = data.newList;
                        this.recommendGameList = data.recommendList;
                        this.bottomGameList = data.bottomList;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            toDetail(gameId) {
                this.$router.push('/detail/' + gameId);
            },

        }
    }
</script>

<style scoped>
    .main-view {
        width: 100%;
        height: 100vh;
        overflow-y: auto;
        background-color: #e8e9f5;
        position: relative;
        z-index: 0;
        -ms-overflow-style: none;  /* IE 和 Edge */
        scrollbar-width: none;/* Firefox */
    }
    .main-view::-webkit-scrollbar {
        display: none;/* 兼容 Chrome, Opera, 和 Safari */
    }

    @font-face{
        font-family:myFont;
        src: url(../../assets/myFont.TTF);
    }
    .top-game-item {
        display: flex;
        align-items: center;
        padding: 15px 30px;
    }
    .top-game-item:hover .game-name-one-line {
        color: #f55050;
    }
    .hor-game-view:hover .game-name-one-line {
        color: #f55050;
    }
    .game-name-one-line {
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-align: left;
        -webkit-line-clamp: 1; /* 这里是超出几行省略 */
        font-size: 20px;
        font-weight: bold;
        color: #333333
    }
    .top-view-name_pre {
        width: 5px;
        height: 25px;
        background-color: #f55050;
    }
    .top-vew-name {
        text-align: left;
        font-family: myFont,serif;
        color: #333333;
        user-select: none;
        margin-left: 10px;
    }
    .top-view-head {
        height: 80px;
        font-size: 35px;
        display: flex;
        align-items: center;
        padding-left: 30px;
    }


    .main-recommend-view {
        width: 49%;
        background-color: #ffffff;
        border-radius: 10px;
        cursor: pointer;
        transition: transform 0.3s ease; /*添加过渡效果*/
        padding: 30px;
        box-sizing: border-box;
    }

    .main-recommend-view:hover {
        transform: translateY(-25px); /*向上移动5像素*/
    }
    /deep/.main-recommend-view img {
        border-radius: 10px;
    }
    .game-tag-view {
        display: flex;
        color: #f3671c;
    }
    .game-tag-view span {
        margin-right: 10px;
    }
    .top-view {
        width: 32%;
        height: 100%;
        background-color: #ffffff;
        border-radius: 10px;
        padding-bottom: 30px;
        cursor: pointer;
    }
    .hor-game-view {
        width: 30%;
        padding-bottom: 5px;
    }
    .download-button {
        width: 180px;
        height: 50px;
        border-radius: 40px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        padding: 0 25px;
        box-sizing: border-box;
        color: #444444;
        user-select: none;
        cursor: pointer;
    }
    .code-view {
        width: 125px;
        height: 145px;
        background-color: #ffffff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
</style>