<template>
  <div class="bg-img">
    <div class="main-page">
      <div style="width: 100%;text-align: left;margin: 90px 20px 0;">
        <img src="../../assets/logo/logo-main.png" style="width: 150px;">
      </div>

      <div class="main-border">
        <van-form @submit="onSubmit">
          <div style="font-size: 18px;font-weight: bold;text-align: left;margin-bottom: 15px;">
              <span>自助充值</span>
          </div>
          <div class="field-main">
            <van-field v-model="account" label="账号" placeholder="请输入账号" autocomplete="off" @blur="checkAccount" class="border-input"/>

            <van-field v-model="uid" label="UID" placeholder="请输入UID" autocomplete="off" type="digit" @blur="checkUid" class="border-input"/>

            <van-field v-model="amount" type="number" label="充值金额" placeholder="请输入充值金额" class="border-input"/>
          </div>
          <div class="tips-panel">
            <div>温馨提示:</div>
            <span>充值成功后虚拟产品一经充值无法退还</span>
          </div>
          <div class="box-button">
            <van-button type="info" round block native-type="submit"
                        style="height: 35px;font-weight: bold;width: 150px"
                        loading-text="充值中..." color="#7a69be">
              确认充值
            </van-button>
          </div>
        </van-form>

        <div class="diy-dialog" v-show="diyDialogShow">
          <div style="text-align: center">提示</div>
          <van-divider/>
          <div style="height: 80px">您将打开支付页面，请问是否继续？<br><br>
            <span style="color: #b3b3b6; font-size: 13px;">注：支付成功后请关闭新打开的支付窗口</span>
          </div>
          <van-divider/>
          <div class="diy-dialog-buttons">
            <div>
              <a @click="diyDialogShow = false">取消</a>
            </div>
            <div>
              <a target="_blank" :href="payUrl" @click="diyDialogShow = false">继续支付</a>
            </div>
          </div>
        </div>
      </div>
      <van-overlay :show="diyDialogShow"/>
    </div>
  </div>
</template>

<script>
  // import { qlOrder,wxAuth, get } from '@/api/request'
  import { Toast } from 'vant'
  import {request} from "@/api/request";

  export default {
    name: 'UserRecharge',
    data() {
      return {
        accountPattern: /^[a-zA-Z0-9_]{6,20}$/,
        submitting: false,
        gameList: [],
        gameNameList: [],
        show: false,
        payType: '',
        account: '',
        uid: '',
        confirmAccount: '',
        game_name: '',
        amount: '',
        diyDialogShow: false,
        payCodeShow: false,
        payCodeImgShow: false,
        payUrl: '',
        openid: '',
        logoUrl: '',
        coinName: '星元',
      }
    },
    mounted() {
      this.logoUrl = require('@/assets/logo/logo.png');
    },
    methods: {
      reset() {
        this.amount = '';
      },
      checkAccount() {
        this.queryGameList(1, this.account);
      },
      checkUid() {
        this.queryGameList(2, this.uid);
      },
      queryGameList(type, value) {
        if(!value) {
          return;
        }
        let url = "/open/checkAndGetPlayGame?checkType=" + type + "&checkValue=" + value;
        let options = {
          url: url,
          method: "get"
        }
        request(options).then(res => {
          if (res.data.success) {
            if (type === 1) {
              let uid = res.data.data.userId + "";
              if (this.uid !== uid) {
                this.uid = uid;
                this.gameList = res.data.data.gameList;
                this.makeData();
              }
            }
            else {
              let account = res.data.data.userName;
              if (this.account !== account) {
                this.account = account;
                this.gameList = res.data.data.gameList;
                this.makeData();
              }
            }

          } else {
            if (type === 1) {
              this.uid = '';
            }
            else {
              this.account = '';
            }
            Toast(res.data.error.message);
            this.gameList = [];
            this.game_name = '';
          }
        })
      },
      makeData() {
        let first = this.gameList[0];
        this.game_name = first.game_name;

        this.gameNameList = [];
        this.gameList.forEach((e, i) => {
          this.gameNameList.push(e.game_name);
        });
      },
      onSubmit() {
        if (!this.account || !this.uid) {
          Toast('请先完善账号信息');
          return ;
        }
        if(!/^[a-zA-Z0-9_]{6,20}$/.test(this.account)){
          Toast('账号为6-12位字母数字或下划线组成')
          return;
        }
        if(!this.amount) {
          Toast('请输入充值金额');
          return;
        }
        if(!/^-?\d+([.]\d{1,2})?$/.test(this.amount)){
          Toast('充值金额格式错误');
          return;
        }
        if (this.amount < 1) {
          Toast('充值金额不能小于1');
          return ;
        }
        this.submitting = true;
        let params = {
          userName: this.account,
          amount: this.amount,
          payType: 24,
          openid: this.openid
        };
        let options = {
          url: `/open/order/xyOrder`,
          method: 'post',
          data: params
        }
        request(options).then(res => {
          this.submitting = false;
          if (res.data.success) {
            console.log('支付成功');
            this.payUrl = res.data.data.payString;
            let ua = navigator.userAgent.toLowerCase();
            if (/iphone|ipad|ipod/.test(ua)) {//判断是否ios
              this.diyDialogShow = true;
            }else{
              window.open(this.payUrl, '');
            }
          }
          else {
            Toast(res.data.error);
          }
        });
      },
      onConfirm(value, index) {
        this.game_name = value;
        this.show = false;
      },
      showPicker() {
        if (!this.account || !this.uid) {
          Toast('请先完善账号信息');
          return ;
        }
        if (this.gameList.length === 0) {
          Toast('暂未查询到玩过的游戏');
          return;
        }
        this.show = true;
      },
      validateSameAccount() {
        return new Promise((resolve) => {
          if (!this.account || !this.confirmAccount) {
            resolve(true);
          }
          if (this.account !== this.confirmAccount) {
            resolve(false);
          }
          resolve(true);

        });
      },
    }
  }
</script>

<style scoped>
.van-cell {
  padding: 7px 16px !important;
}
.bg-img {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #f8f8f8;
  background-image: url(../../assets/main-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.main-page {
  max-width: 400px;
  margin: 0 auto;
}

.main-border {
  max-width: 500px;
  text-align: center;
  width: 90%;
  padding: 10px 20px;
  box-sizing: border-box;
  margin: 30px auto 0;
}

.box-button {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 15px;
}

.van-field {
  outline: 1px #dbdbdb solid;
  border-radius: 3px;
}

.van-field:focus-within {
  outline: 2px #7a69be solid;
}
.field-main .border-input {
  margin-top: 10px;
}
.field-main .border-input:first-child {
  margin-top: 0;
}

.tips-panel{
  margin: 10px 0;
  text-align: left;
  font-size: 12px;
  color: #666666;
  line-height: 20px;
}
.tips-panel span {
  display: block;
}
.diy-dialog {
  z-index: 9;
  top: 35%;
  background-color: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 8px 1px 8px;
  text-align: left;
  font-size: 14px;
  width: 70%;
  border-radius: 10px;
  left: 14.5%;
}
.diy-dialog .diy-dialog-buttons {
  padding: 0 15px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  height: 30px;
}
.diy-dialog-buttons a {
  color: #1783d6;
}
.diy-dialog-buttons div {
  width: 100%;
  text-align: center;
}
.diy-dialog .van-divider--hairline {
  margin: 8px 0;
}

</style>
