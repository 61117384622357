<template>
    <div class="bg-img">
        <div class="main-page">
            <div style="width: 100%;text-align: left;margin-top: 60px;">
                <img src="../../assets/logo/logo-main.png" style="width: 150px;">
                <div class="main-border">
                    <van-form @submit="submit" ref="submitForm">
                        <div style="font-size: 18px;font-weight: bold;text-align: left;margin-bottom: 15px;">
                            <span>绑定微信</span>
                        </div>
                        <div class="field-main">
                            <van-field v-model="userName" label="账号" placeholder="请输入账号" autocomplete="off" class="border-input"/>

                            <van-field v-model="password" label="密码" type="password" style="margin-top: 10px;" placeholder="请输入登录密码" autocomplete="off" class="border-input"/>
                        </div>
                        <div class="box-button">
                            <van-button type="info" round block native-type="submit" :loading="loading"
                                        style="height: 35px;font-weight: bold;margin-top: 40px;"
                                        loading-text="登录中" color="#7a69be">
                                绑 定
                            </van-button>
                        </div>
                    </van-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Dialog} from 'vant';
import crypto from '@/utils/crypto.js';
import {request} from "@/api/request";

export default {
    name: "bindwx",
    data() {
        return {
            appid: 'wxa655a371cb93238e',
            code: '',
            userName: '',
            password: '',
            loading: false,
        }
    },
    mounted() {
        let code = this.getUrlKey("code");
        if (!code) {
            this.getCodeApi();
        }
        this.code = code;
    },
    methods: {
        getUrlKey(name) {//获取url 参数
            return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null;
        },
        getCodeApi() {//获取code   
            let urlNow = encodeURIComponent(window.location.href);
            // let urlNow = encodeURIComponent("https://game.daoyigame.com/#/bind/wechat");
            console.log('urlNow', urlNow)
            // let scope='snsapi_userinfo';    //snsapi_userinfo snsapi_base   //静默授权 用户无感知
            let url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + this.appid + '&redirect_uri=' + urlNow + '&response_type=code&scope=snsapi_userinfo#wechat_redirect';
            window.location.href = url;
        },
        submit() {
            if (this.userName === '' || this.password === '') {
                Dialog.alert({
                    message: '账号和密码不能为空',
                }).then(() => {

                });
                return;
            }
            let options = {
                url: `/h5/bindwx`,
                data: {
                    userName: this.userName,
                    password: crypto.encrypt(this.password),
                    code: this.code
                }
            }
            request(options).then(res => {
                if (res.data.success) {
                    Dialog.alert({
                        message: '绑定成功',
                    }).then(() => {
                          this.userName = '';
                          this.password = '';
                    });
                } else {
                    Dialog.alert({
                        message: res.data.error.message,
                    }).then(() => {

                    });
                }
            });
        },
    },


}
</script>

<style scoped>
.bg-img {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: #f8f8f8;
    background-image: url(../../assets/main-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.main-page {
    max-width: 400px;
    min-width: 350px;
    //margin: 0 auto;
    //margin: 20px;
}

.main-border {
    max-width: 500px;
    text-align: center;
    width: 90%;
    padding: 10px 20px;
    box-sizing: border-box;
    margin: 30px auto 0;
    /*border: 1px solid #eceaea;
    background-color: #ffffff;
    box-shadow: 2px 2px 10px 3px rgba(0,0,0,.1);*/
}

.box-button {
    margin: 15px 3px 0;
}

.box-button .text-button {
    font-size: 0.8rem;
    margin-top: 0.8rem;
    color: #888888;
    text-align: right;
}

.van-field {
    outline: 1px #dbdbdb solid;
    border-radius: 3px;
}

.van-field:focus-within {
    outline: 2px #7a69be solid;
}
</style>