<template>
    <div class="main-container">
        <van-button @click="pay">支付</van-button>
        <van-button @click="uploadGameRole">上报角色</van-button>
        <van-button @click="repeatLogin">登录被顶</van-button>
    </div>
</template>

<script>

    import xySdk from '@/utils/sdk/xingyou-sdk';
    import {md5} from '@/utils/md5';

    export default {
        name: 'gameTest',
        data() {
            return {
                xySdk: window.DAOYI_SDK,
                gameId: 107,
                userId: 7,
                sdkInit: false,
                testKey : '3536347c2ded4c45819af4ace36a4939',
            };
        },
        mounted() {
            let params = {
                gameId: this.gameId
            }
            this.xySdk.config(params);
        },
        methods: {
            uploadGameRole() {
                let roleParam = {
                    gameId: this.gameId,
                    userId: this.userId,
                    serverId: "17001",
                    serverName: "电信1区",
                    roleId: "122001",
                    roleName: "zs",
                    roleLevel: "10",
                    combatNum: "12000000",//战斗力
                    vipLevel: "12",//vip等级
                    time: new Date().getTime() + "",
                    eventType: "1",
                    partyId: "20",
                    partyMasterId: "146002",
                    partyMasterName: "老大",
                    partyName: "宗门1",
                };

                roleParam.sign = this.sign(roleParam)
                this.xySdk.reportRoleInfo(roleParam);
            },
            //⽤户点击商品下单
            pay() {
                let userId = localStorage.getItem('accountId');
                let order = {
                    gameId: this.gameId,
                    userId: this.userId,
                    serverId: "17001",
                    serverName: "电信1区",
                    roleId: "122001",
                    roleName: "zs",
                    goodsName: "100元宝",
                    money: "100",
                    cpOrderId: "xingyou-" + new Date().getTime(),
                    time: new Date().getTime() + "",
                };
                order.sign = this.sign(order)
                this.xySdk.openPayPage(order);
            },
            sign(data) {
                let sortedParam = this.sorter(data);
                let needSignatureStr = "";
                for (let key in sortedParam) {
                    let value = sortedParam[key];
                    if (value) {
                        needSignatureStr = needSignatureStr + key + '=' + value + '&';
                    }
                }
                needSignatureStr += this.testKey;
                console.log('需加密串:' + needSignatureStr);
                let sign = md5(needSignatureStr)
                console.log('加密结果:' + sign);
                return sign;
            },
            sorter(inObject) {
                let sortedJson = {};
                let sortedKeys = Object.keys(inObject).sort();
                for (let i = 0; i < sortedKeys.length; i++) {
                    sortedJson[sortedKeys[i]] = inObject[sortedKeys[i]]
                }
                return sortedJson;
            },
            repeatLogin() {
                this.xySdk.offline();
            },

        }
    }
</script>

<style scoped>
    .main-container {

    }
</style>
