import axios from './axios'

export async function request(options) {
    if (!options.data) {
        options.data = {};
    }
    options.data.gameId = localStorage.getItem("gameId");
    options.data.device = '';
    options.data.udid = '';
    return axios({
        url: options.url,
        method: options.method || 'post',
        data: options.data
    })
}




