<template>
    <div class="main-view">
        <home-nav></home-nav>
        <gzh-code-view></gzh-code-view>
        <div style="width: 100%;min-height: calc(100vh - 380px); max-width: 1200px;margin: 0 auto;">
            <div style="width: 100%;height:70px; background-color: #ffffff;border-radius: 10px;margin-top: 20px;">
                <div style="height: 70px;padding-left: 50px;display: flex;align-items: center;color: #666666">
                    <span>当前位置：</span>
                    <span style="margin-left: 10px;">首页</span>
                    <span style="margin-left: 10px;">></span>
                    <span style="margin-left: 10px;">联系客服</span>
                </div>

            </div>

            <div class="kf-view">
                <div class="common-view">
                    <div class="top-view-head">
                        <div class="top-view-name_pre"></div>
                        <div class="top-vew-name">
                            QQ客服
                        </div>
                    </div>
                    <img src="../../assets/qq.png" style="width: 90px;height: 90px;margin-top: 30px;">
                    <div style="font-size: 30px;margin-top: 30px;">QQ:3758985932</div>
                    <div style="color: #888888;margin-top: 50px;">
                        工作时间  9：00 - 18：00
                    </div>
                </div>
                <div class="common-view">
                    <div class="top-view-head">
                        <div class="top-view-name_pre"></div>
                        <div class="top-vew-name">
                            微信客服
                        </div>
                    </div>

                    <img src="../../assets/gzh.jpg" style="width: 200px;height: 200px;margin-top: 0">
                    <div style="font-size: 15px;margin-top: 5px;">请扫码关注公众号进入客服页面</div>
                    <div style="color: #888888;margin-top: 10px;">
                        工作时间  9：00 - 18：00
                    </div>
                </div>
                <div class="common-view" style="display: flex;align-items: center;justify-content: center;">
                    <div style="font-size: 25px;color: #888888">更多服务即将完善</div>
                </div>
            </div>


        </div>

        <home-footer></home-footer>
    </div>
</template>

<script>
    import homeNav from "@/components/HomeNav";
    import homeFooter from "@/components/HomeFooter";
    import gzhCodeView from "@/components/GzhCodeView";

    export default {
        name: "HomeKf",
        components: {
            homeNav,
            homeFooter,
            gzhCodeView,
        },
        data() {
            return {}
        },
        mounted() {

        },
        methods: {

        }
    }
</script>

<style scoped>
    .main-view {
        min-width: 1400px;
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
        background-color: #eaeef3;
        position: relative;
        -ms-overflow-style: none;  /* IE 和 Edge */
        scrollbar-width: none;/* Firefox */
    }
    .main-view::-webkit-scrollbar {
        display: none;/* 兼容 Chrome, Opera, 和 Safari */
    }
    .kf-view {
        width: 100%;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .common-view {
        width: 350px;
        height: 400px;
        background-color: #ffffff;
        border-radius: 10px;
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .top-view-name_pre {
        width: 5px;
        height: 20px;
        background-color: #f55050;
    }
    .top-vew-name {
        text-align: left;
        font-family: myFont,serif;
        color: #1a66e5;
        user-select: none;
        margin-left: 10px;
    }
    .top-view-head {
        height: 80px;
        font-size: 25px;
        display: flex;
        align-items: center;
        padding-left: 30px;
    }
</style>