<template>
    <div class="pay-code-panel">
        <div class="qr-code" ref="qrCodeUrl"></div>
    </div>
</template>

<script>
    import QRCode from 'qrcodejs2'
    import {Toast} from "vant";

    export default {
        name: "WxPayQrCode",
        data() {
            return {
                payCode: '',
                qrCode: {},
            }
        },
        mounted() {
            this.payCode = decodeURIComponent(this.$route.fullPath.split('payCode=')[1]);
            this.init();
        },
        methods: {
            init() {
                this.creatQrCode();
            },
            creatQrCode() {
                this.$refs.qrCodeUrl.innerHTML = ""
                if (this.qrCode._oQRCode) {
                    this.qrCode.clear(); //清除
                }
                this.qrCode = new QRCode(this.$refs.qrCodeUrl, {
                    text: this.payCode,
                    width: 190,
                    height: 190,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: 3
                });
            }
        }
    }
</script>

<style scoped>
    .pay-code-panel{
        text-align: center;
        overflow: hidden;
        height: 200px;
    }

    .qr-code{
        /*实现垂直居中*/
        display: inline-block;
        align-items: center;
        justify-content: center;
        text-align: center;
        overflow: hidden;
    }

</style>