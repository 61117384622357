let _xy_instance = window.XINGYOU_SDK || {};
_xy_instance.clientVersion = "1.0.0";
_xy_instance.gameId = 0;
_xy_instance.init = function () {

};

_xy_instance.config = function (params) {
    _xy_instance.gameId = params.gameId;
};

_xy_instance.openPayPage = function (order) {
    sendMsgToParent({operation: "openPayPage", param: order});
    try {
        window.androidWebviewJS.openPayPage(JSON.stringify(order));
    } catch (e) {
    }
};

_xy_instance.reportRoleInfo = function (param) {
    sendMsgToParent({operation: "reportRoleInfo", param: param});
    try {
        window.androidWebviewJS.reportRoleInfo(JSON.stringify(param));
    } catch (e) {
    }
};

_xy_instance.offline = function () {
    sendMsgToParent({operation: "offline"});
    try {
        window.androidWebviewJS.offline();
    } catch (e) {
    }
};

_xy_instance.init();
window.XINGYOU_SDK = _xy_instance;

function sendMsgToParent(post) {
    window.parent.postMessage(post, '*')
}
