<template>
    <div class="main-container">

        <div
            style="font-size: 18px;font-weight: bold;text-align: left;margin-bottom: 15px;margin-left: 10px;color: #666666">
            <span>实名认证</span>
        </div>

        <van-form @submit="onSubmit" ref="submitForm">

            <div style="margin: 10px 10px;color: #666666">

                <div style="text-align: left;font-size: 13px;margin-bottom: 5px;">真实姓名</div>
                <van-field placeholder="请输入您的真实姓名" style="margin-top: 5px;" v-model="realName" clearable/>

                <div style="text-align: left;font-size: 13px;margin-bottom: 5px;margin-top: 10px;">身份证号</div>
                <van-field placeholder="请输入您的身份证号" style="margin-top: 5px;" v-model="carId" clearable/>

                <div class="left-top-title">
                    <span>进入游戏之前请先完成实名认证，未满18周岁无法进入游戏</span>
                </div>

                <div class="box-button">
                    <van-button type="info" round block native-type="submit" :loading="loading"
                                style="height: 35px;font-weight: bold;"
                                loading-text="正在认证"
                                color="#7a69be">
                        立即认证
                    </van-button>

                    <div v-if="false"
                         style="font-size: 12px;margin-top: 15px;display: flex;justify-content: flex-end;padding: 0 3px;">
                        <div @click="logout" style="color: #888888">切换其他账号</div>
                    </div>
                </div>

            </div>
        </van-form>
    </div>
</template>

<script>

import {request} from '@/api/request'
import {Toast} from 'vant'
import crypto from "@/utils/crypto";

export default {
    name: 'realName',
    data: function () {
        return {
            realName: '',
            carId: '',
            loading: false,
            missShow: false,
            realNamePattern: /^[\u4E00-\u9FA5]{1,5}$/,
            carIdPattern: /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][012])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/,
        }
    },
    mounted() {

    },
    methods: {
        initData() {
            let user = JSON.parse(localStorage.getItem('user'));
            this.carId = user.cardNo;
            this.realName = user.realName;
        },
        onSubmit() {
            let ok = this.checkForm();
            if (ok !== 'ok') {
                Toast(ok);
                return;
            }

            this.loading = true;
            let options = {
                url: "/h5/personCheck",
                data: {
                    cardNo: this.carId,
                    realName: this.realName,
                }
            };
            request(options).then((res) => {
                this.loading = false;
                if (res.data.success === true) {
                    Toast.success('实名认证成功');
                    let user = JSON.parse(localStorage.getItem('user'));
                    user.isUserCheck = res.data.data;
                    localStorage.setItem('user', JSON.stringify(user));
                    this.$parent.$parent.showSelectAccount();

                } else {
                    Toast(res.data.error.message);
                }
            });
        },
        checkForm() {
            if (!this.realName) {
                return "请输入真实姓名";
            }
            /* if (!this.realNamePattern.test(this.realName)) {
               return "真实姓名格式错误";
             }*/
            if (!this.carId) {
                return "请输入身份证号码";
            }
            if (!this.carIdPattern.test(this.carId)) {
                return "身份证号码格式错误";
            }
            return "ok";
        },
    }
}
</script>

<style scoped>
.main-container {
    padding: 10px 15px;
}

.left-top-title {
    text-align: left;
    color: #888888;
    vertical-align: center;
    font-size: 12px;
    height: 40px;
    margin-top: 7px;
}

.left-top-title h2 {
    display: inline-block;
    font-size: 1.3rem;
    margin: 0;
}

.van-cell {
    padding: 6px 16px;
}

.box-button {
    margin: 10px 0 10px;
}

.van-field {
    outline: 1px #dbdbdb solid;
    border-radius: 3px;
}

.van-field:focus-within {
    outline: 2px #7a69be solid;
}
</style>
