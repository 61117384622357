<template>
    <div class="space-main">
        <div class="space-text-view">
            <div>一支穿云箭，boss得进阎王殿</div>
            <div>邀请好友0元购</div>
            <div>福利积分免费享</div>
            <div>微信红包免费砍</div>
        </div>

        <div style="position: absolute;bottom: 110px;text-align: center;left: 0;right: 0;margin: auto;">
            <span style="color: #7a7a7a;font-size: 14px;">
                我正在0元购，快来帮我消灭它
            </span>
        </div>

        <div @click="goHelp" class="space-button" >
            立马协助
        </div>

    </div>

</template>

<script>


export default {
    name: 'SpaceHelpPage',
    data() {
        return {
            helpUrl: '',
        };
    },
    mounted() {
        let boss = this.$route.query.boss;
        this.helpUrl = 'https://game.daoyigame.com/space/?boss=' + boss;
    },
    methods: {
        goHelp() {
            window.location.href = this.helpUrl;
        },
    }
};
</script>

<style scoped>
    .space-main {
        max-width: 500px;
        margin: 0 auto;
        width: 100vw;
        height: 100vh;
        background: url("../../assets/help-bg.png");
        background-size: 100% 100%;
        padding-top: 10px;
        box-sizing: border-box;
    }
    .space-text-view {
        width: 90%;
        margin: 250px auto 0;
        background-color: rgba(255, 255, 255, 0.43);
        border-radius: 12px;
        padding: 15px;
        font-size: 16px;
        font-weight: bold;
        color: #4d4e52;
        box-sizing: border-box;
        line-height: 35px;
        text-align: left;
    }
    .space-button {
        width: 300px;
        height: 40px;
        border-radius: 40px;
        text-align: center;
        line-height: 40px;
        color: #FFFFFF;
        background-color: #5A6EFC;
        position: absolute;
        bottom: 50px;
        left: 0;
        right: 0;
        margin: auto;
        font-size: 16px;
        font-weight: bold;
    }
</style>