<template>
    <div class="main-view">
        <home-nav class="m-hidden-view"></home-nav>
        <gzh-code-view class="gzh-hidden-view"></gzh-code-view>

        <div class="m-hidden-view">
            <van-image v-if="!!topImageUrl" :src="topImageUrl" class="top-img" fit="cover"/>
            <van-image v-else :src="require('../../assets/logo/top.png')"  class="top-img"  fit="cover"/>
        </div>

        <div class="m-title">
            <div @click="back" style="margin-right: 5px;width: 30px;height: 30px;display: flex;align-items: center;justify-content: center;">
                <van-icon name="arrow-left" size="20"/>
            </div>
            {{gameName}}
        </div>

        <div style="width: 100%;min-height: calc(100vh - 380px); max-width: 1200px;margin: 0 auto;">
            <div class="m-hidden-view" style="width: 100%;height:70px; background-color: #ffffff;border-radius: 10px;margin-top: -30px;z-index: 999;position: relative;">
                <div style="height: 70px;padding-left: 50px;display: flex;align-items: center;color: #666666">
                    <span>当前位置：</span>
                    <span style="margin-left: 10px;">首页</span>
                    <span style="margin-left: 10px;">></span>
                    <span style="margin-left: 10px;">{{gameName}}</span>
                </div>
            </div>

            <div class="game-info-view">
                <div class="game-base-view">
                    <img :src="icon" class="game-icon">
                    <div class="game-title-view">
                        <span class="game-name">{{gameName}}</span>
                        <div class="game-tag-view">
                            <span v-for="tag in tagList">{{tag}}</span>
                        </div>
                        <span>{{gameTopic}} <span style="color: #15a26d;margin-left: 15px;">{{gameSize}}</span></span>
                    </div>

                    <div class="m-hidden-view" style="width: 200px;height: 210px;display:flex;flex-direction: column;align-items: center;justify-content: center; position: absolute;right: 30px;">
                        <div  @click="downloadGame" class="download-button">
                            <img v-if="false" src="../../assets/android-white.png" style="width: 25px;height: 25px;margin-right: 10px;">
                            <span>游戏下载</span>
                        </div>

                        <div v-if="true" class="online-button" style="margin-top: 20px;">
                            <span>在线玩</span>
                        </div>

                    </div>

                </div>

                <div  class="m-hidden-view" style="margin-left: 20px; width: 280px;height:210px; background-color: #ffffff;border-radius: 10px;display: flex;flex-direction: column; align-items: center;justify-content: center;">
                    <div class="pay-code-panel">
                        <div class="qr-code" ref="qrCodeUrl"></div>
                    </div>
                    <span style="font-size: 13px;color: #888888;margin-top: 10px;">手机扫一扫下载游戏</span>
                </div>
            </div>


            <div class="game-desc-view">
                <div class="top-vew-name">
                    游戏简介
                </div>

                <div class="game-desc-text">
                    <span>{{gameDesc}}</span>
                </div>

                <div ref="scrollable" class="video-img-view" @mousedown="startMove" @mouseup="endMove" @mouseleave="endMove" @mousemove="change">

                    <video v-if="!!videoUrl" :src="videoUrl" class="game-video" :controls="true" :autoplay="false" />

                    <div class="game-image-view">
                        <!--<img draggable="true" v-for="imageUrl in introImageUrlList" :src="imageUrl" style="height: 400px;margin-left: 15px;border-radius: 5px;"/>-->

                        <div v-for="(imageUrl,index) in introImageUrlList" class="game-img-list">
                            <el-image :id="'img' + index" :style="isMoving ? 'pointer-events: none' : ''"
                                    class="game-image" :src="imageUrl"
                                    :preview-src-list="introImageUrlList">
                            </el-image>
                        </div>


                    </div>
                </div>
            </div>

            <div  class="m-bottom-view">
<!--                <div class="ios-div" @click="goGuide" v-if="downloading">iOS如何授权</div>-->
                <div class="m-button"  @click="downloadGame">{{buttonText}}</div>
                <!--                <div class="m-button" v-if="appStatus === 1">已安装，点击打开</div>
                                <div class="m-button" v-if="appStatus === 2">已下载，点击安装</div>
                                <div class="m-button" v-if="appStatus === 3" @click="downloadGame">已暂停，点击继续</div>-->
            </div>

        </div>

        <van-overlay :show="isWeixin">
            <div class="overlay-panel">
                <div>
                    <img src="../../assets/weixin_arrow.png" style="width: 80px;">
                </div>
                <div class="overlay-info">
                    <p>
                        请点击右上角 ... 在浏览器中打开
                    </p>
                </div>
            </div>
        </van-overlay>

        <home-footer class="m-hidden-view"></home-footer>
    </div>
</template>

<script>
    import {request} from "@/api/request";
    import homeNav from "@/components/HomeNav";
    import homeFooter from "@/components/HomeFooter";
    import gzhCodeView from "@/components/GzhCodeView";
    import {Dialog, Toast} from "vant";
    import QRCode from "qrcodejs2";

    export default {
        name: "HomeDetail",
        components: {
            homeNav,
            homeFooter,
            gzhCodeView,
        },
        data() {
            return {
                gameId: '',
                gameName: '',
                gameDesc: '',
                gameTopic: '',
                gameSize: '',
                icon: '',
                tagList: [],
                mobileUrl: '',
                qrCode: {},
                videoUrl: '',
                introImageUrlList: [],
                topImageUrl: '',
                isMouseDown: false,
                isMoving: false,
                startX: 0,
                scrollLeft: 0,
                downloading: false,
                androidUrl: '',
                iosUrl: '',
                from: '',
                gameSystem: 0,
                buttonText: '游戏下载',
                process: '',
                appStatus: 0,
                packageName: '',
                isWeixin: false,
            }
        },
        mounted() {
            this.isWeixin = this.isWeiXin();
            this.mobileUrl = window.location.href;
            this.gameId = this.$route.path.split('/')[2]
            this.from = localStorage.getItem('from');
            this.getGameInfo();
            this.creatQrCode();
            window.refreshProcess = this.refreshProcess;
            window.refreshAppInfo = this.refreshAppInfo;
        },
        methods: {
            back() {
                this.$router.back();
            },
            isWeiXin() {
                let ua = window.navigator.userAgent.toLowerCase()
                //通过正则表达式匹配ua中是否含有MicroMessenger字符串
                return ua.match(/MicroMessenger/i) == 'micromessenger'
            },
            startMove(e) {
                this.isMouseDown = true;
                this.startX = e.pageX - this.$refs.scrollable.offsetLeft;
                this.scrollLeft = this.$refs.scrollable.scrollLeft;
            },
            endMove(e) {
                this.isMouseDown  = false;
                this.isMoving  = false;
            },
            change(e) {
                if (!this.isMouseDown) return;
                e.preventDefault();
                let x = e.pageX - this.$refs.scrollable.offsetLeft
                let walk = (x - this.startX) * 1; // 调整滚动速度
                if (walk !== 0) {
                    this.isMoving  = true;
                    this.$refs.scrollable.scrollLeft = this.scrollLeft - walk;
                }
            },
            refreshProcess(p) {
                this.process = p + "%";
                this.buttonText = "正在下载 (" + this.gameSize + ") " + p + "%";
                if (p === 100) {
                    this.buttonText = "下载完成";
                }
            },
            refreshAppInfo() {
                let appInfoStr = window.androidJs.getAppInstallInfo(this.packageName, this.androidUrl);
                //let appInfoStr = window.androidJs.getAppInstallInfo(this.packageName, "https://cdn.7li.club/eaoojlgn/1/shanhaijhxl.apk");
                let appInfo = JSON.parse(appInfoStr);
                this.appStatus = appInfo.status;
                if (this.appStatus === 1) {
                    this.buttonText = "已安装，点击打开";
                }
                else if (this.appStatus === 2) {
                    this.buttonText = "已下载，点击安装";
                }
            },
            getGameInfo() {
                this.showLoading = true;
                let options = {
                    url:"/web/getGameInfo?id=" + this.gameId,
                    method: 'get'
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        let data = res.data.data;
                        this.gameName = data.gameName;
                        this.icon = data.icon;
                        this.gameDesc = data.gameDesc;
                        this.gameTopic = data.gameTopic;
                        this.gameSize = data.gameSize;
                        this.tagList = data.tagList;
                        this.videoUrl = data.videoUrl;
                        this.topImageUrl = data.topImageUrl;
                        this.introImageUrlList = data.introImageUrlList;
                        this.androidUrl = data.androidUrl;
                        this.iosUrl = data.iosUrl;
                        this.gameSystem = data.gameSystem;
                        this.packageName = data.packageName;

                        if (this.gameSize) {
                            this.buttonText = "游戏下载 (" + this.gameSize + ")"
                        }

                        if (this.from === 'android_box') {
                            this.packageName = res.data.data.package_name;
                            let appInfoStr = window.androidJs.getAppInstallInfo(this.packageName, this.androidUrl);
                            //let appInfoStr = window.androidJs.getAppInstallInfo(this.packageName, "https://cdn.7li.club/eaoojlgn/1/shanhaijhxl.apk");
                            console.log('appInfo', appInfoStr);
                            let appInfo = JSON.parse(appInfoStr);
                            this.appStatus = appInfo.status;
                            if (this.appStatus === 1) {
                                this.buttonText = "已安装，点击打开";
                            }
                            else if (this.appStatus === 2) {
                                this.buttonText = "已下载，点击安装";
                            }
                            else if (this.appStatus === 3) {
                                //let p = appInfo.data;
                                //this.buttonText = "正在下载 (" + this.gameSize + ") " + p + "%";
                            }
                            else if (this.appStatus === 4) {
                                let p = appInfo.data;
                                this.buttonText = "已暂停 (" + this.gameSize + ") " + p + "%";
                            }
                        }
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            creatQrCode() {
                this.$refs.qrCodeUrl.innerHTML = ""
                if (this.qrCode._oQRCode) {
                    this.qrCode.clear(); //清除
                }
                this.qrCode = new QRCode(this.$refs.qrCodeUrl, {
                    text: this.mobileUrl,
                    width: 140,
                    height: 140,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: 3

                });
            },
            goGuide() {
                this.$router.push({path: '/download/iosGuide'})
            },
            downloadGame() {
                let ua = navigator.userAgent.toLowerCase();
                let isMobile = this.isMobile();
                let isIOS = /iphone|ipad|ipod/.test(ua);
                let isIOSPC = /macintosh|mac|ipod/.test(ua);

                if (isMobile) {
                    if (isIOS) {   //判断是否ios
                        if (this.gameSystem === 1) {
                            this.showDialog('当前游戏暂时不支持iOS，请使用安卓设备进行游戏')
                            return;
                        }
                        let msg = "点击允许后，找到 对应游戏文件 点击安装，输入锁屏密码，安装之后，在桌面进行查看";
                        this.showDialog(msg);
                        window.location.href = this.iosUrl;
                        this.downloading = true;
                    } else {
                        if (this.gameSystem === 2) {
                            this.showDialog('当前游戏暂时不支持安卓，请使用iOS设备进行游戏')
                            return
                        }
                        if (this.from === 'android_box') {//游戏盒子下载游戏
                            window.androidJs.startDownload(this.androidUrl);
                        }
                        else {
                            window.location.href = this.androidUrl
                        }
                    }
                }
                else {
                    if (isIOSPC) {
                        if (this.gameSystem === 1) {
                            this.showDialog('当前游戏暂时不支持iOS，请使用安卓设备进行游戏')
                            return
                        }
                        let msg = '';
                        if (this.isSelfSigned) {
                            msg = "点击允许后，再点击去安装证书，找到刚下载好的描述文件点击安装，输入锁屏密码"
                        } else {
                            msg = '点击允许安装后，在桌面查看下载进度，下载完毕之后设置信任，操作步骤查看ios授权示例';
                        }

                        this.showDialog(msg);
                        window.location.href = this.iosUrl
                        this.downloading = true
                    } else {
                        window.location.href = this.androidUrl
                    }
                }
            },
            showDialog(msg) {
                Dialog.alert({
                    title: '提示',
                    messageAlign: 'left',
                    message: msg
                })
            },
            isMobile() {
                let browser = {
                    versions: function () {
                        let u = navigator.userAgent, app = navigator.appVersion;
                        return {
                            trident: u.indexOf('Trident') > -1, //IE内核
                            presto: u.indexOf('Presto') > -1, //opera内核
                            webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
                            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,//火狐内核
                            mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
                            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
                            android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, //android终端
                            iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
                            iPad: u.indexOf('iPad') > -1, //是否iPad
                            webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
                            weixin: u.indexOf('MicroMessenger') > -1, //是否微信 （2015-01-22新增）
                            qq: u.match(/\sQQ/i) == " qq" //是否QQ
                        };
                    }(),
                    language: (navigator.browserLanguage || navigator.language).toLowerCase()
                }
                // return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
                return browser.versions.mobile || browser.versions.android || browser.versions.ios;
            },
        }
    }
</script>

<style scoped>
    .main-view {
        min-width: 1400px;
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
        background-color: #eaeef3;
        position: relative;
        -ms-overflow-style: none;  /* IE 和 Edge */
        scrollbar-width: none;/* Firefox */
    }
    .main-view::-webkit-scrollbar {
        display: none;/* 兼容 Chrome, Opera, 和 Safari */
    }
    .top-img {
        width: 100%;
        height: 270px;
    }
    .game-name {
        text-align: left;
        font-size: 28px;
        font-weight: bold;
        color: #333333;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1; /* 这里是超出几行省略 */
    }
    .game-tag-view {
        display: flex;
        white-space: nowrap;
    }
    .game-tag-view span {
        margin-right: 10px;
        //background-color: rgba(238, 109, 109, 0.1);
        border: 1px solid #f14848;
        border-radius: 3px;
        color: #f14848;
        padding: 2px 6px;
    }
    .download-button {
        width: 170px;
        height: 50px;
        border-radius: 40px;
        background-color: #7a69be;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: bold;
        box-sizing: border-box;
        color: #ffffff;
        user-select: none;
        cursor: pointer;
    }
    .online-button {
        width: 170px;
        height: 50px;
        border-radius: 40px;
        background-color: #ffffff;
        border: 2px solid #7a69be;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: bold;
        box-sizing: border-box;
        color: #7a69be;
        user-select: none;
        cursor: pointer;
    }
    .pay-code-panel{
        text-align: center;
        overflow: hidden;
        width: 140px;
        height: 140px;
    }
    .qr-code{
        display: inline-block;
        align-items: center;
        justify-content: center;
        text-align: center;
        overflow: hidden;
    }
    .top-vew-name {
        text-align: left;
        font-family: myFont,serif;
        color: #1a66e5;
        user-select: none;
        font-size: 35px;
    }

    .game-info-view {
        width: 100%;
        display: flex;
        margin-top: 20px;
    }

    .game-base-view {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 30px;
        height: 210px;
        position: relative;
        background-color: #ffffff;
        border-radius: 10px;
        overflow: hidden;
    }
    .game-icon {
        width: 140px;
        height: 140px;
        border-radius: 20px;
    }
    .game-title-view {
        display: flex;
        height: 125px;
        color: #666666;
        margin-left: 25px;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        font-size: 16px;
    }
    .game-desc-view {
        width: 100%;
        background-color: #ffffff;
        border-radius: 10px;
        margin-top: 20px;
        padding: 20px 30px;
        box-sizing: border-box;
        margin-bottom: 20px;
    }
    .game-desc-text {
        line-height: 33px;
        margin-top: 16px;
        text-align: left;
        font-size: 16px;
        color: #444444;
    }
    .video-img-view {
        width: 1140px;
        margin-top: 25px;
        overflow: hidden;
        white-space: nowrap;
        position: relative;
        font-size: 0;
        -webkit-overflow-scrolling: touch;
    }
    .game-video {
        height: 400px;
        border-radius: 5px;
    }
    .game-image {
        width: 225px;
        height: 400px;
        border-radius: 5px;
    }
    .game-img-list {
        margin-left: 15px;
        display: inline-block
    }
    .game-image-view {
        white-space: nowrap;
        overflow: hidden;
        display: inline-block
    }
    .m-bottom-view {
        height: 55px;
        position: fixed;
        bottom: 0;
        background-color: #ffffff;
        width: 100%;
        display: none;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        box-sizing: border-box;
    }
    .m-button {
        width: 80%;
        height: 40px;
        border-radius: 40px;
        background-color: #7a69be;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
        box-sizing: border-box;
        color: #ffffff;
        user-select: none;
        cursor: pointer;
    }
    .m-title {
        display: none;
        font-size: 18px;
        color: #333333;
        width: 100%;
        height: 60px;
        text-align: left;
        align-items: center;
        padding-left: 10px;
        position: fixed;
        z-index: 999;
        background-color: #eaeef3;
    }

    .van-overlay {
        z-index: 1000;
    }
    .overlay-panel{
        text-align: right;
        margin-right: 15px;
        margin-top: 10px;

    }
    .overlay-info {
        text-align: center;
        width: 80%;
        margin: 20px auto;
        border-radius: 60px;
        color: white;
        font-weight: 600;
    }

    @media screen and (max-width: 1300px) {
        .gzh-hidden-view {
            display: none !important;
        }
    }

    @media screen and (max-width: 900px) {
        .m-hidden-view {
            display: none !important;
        }
        .m-title {
            display: flex;
        }
        .main-view {
            min-width: unset;
        }
        .game-info-view {
            width: 95%;
            margin: 60px auto 0;
        }
        .game-base-view {
            height: 110px;
            padding: 0 20px;
        }
        .game-icon {
            width: 80px;
            height: 80px;
            border-radius: 10px;
        }
        .game-title-view {
            height: 80px;
            font-size: 12px;
            margin-left: 15px;
        }
        .game-name {
            font-size: 15px;
        }
        .game-desc-view {
            width: 95%;
            margin: 10px auto 60px;
            padding: 15px 20px;
        }
        .top-vew-name {
            font-size: 22px;
        }
        .game-desc-text {
            line-height: 24px;
            font-size: 14px;
            margin-top: 5px;
        }
        .video-img-view {
            width: 100%;
            overflow: auto;
            margin-top: 10px;
            -ms-overflow-style: none;  /* IE 10+ */
            scrollbar-width: none;  /* Firefox */
        }
        .game-video {
            width: 95%;
            height: unset;
        }
        .game-image {
            width: unset;
            height: calc((95vw - 40px) * 0.95 * 9 / 16);
        }
        .game-img-list {
            margin-left: 5px;
        }
        .main-view {
            -ms-overflow-style: none;  /* IE 10+ */
            scrollbar-width: none;  /* Firefox */
        }
        .main-view::-webkit-scrollbar {
            display: none;
        }
        .video-img-view::-webkit-scrollbar {
            display: none;
        }
        .game-image-view {
            overflow: unset;
        }
        .top-img {
            height: 150px;
        }
        .m-bottom-view {
            display: flex;
        }
    }
</style>