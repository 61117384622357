<template>
    <div class="main-container">

        <div style="font-size: 18px;font-weight: bold;text-align: left;margin-bottom: 15px;margin-left: 10px;color: #666666">
            <span>绑定手机</span>
        </div>

        <van-form @submit="onSubmit" ref="submitForm">

            <div style="margin: 10px 10px;color: #666666">

                <div style="text-align: left;font-size: 13px;margin-bottom: 5px;">手机号码</div>
                <van-field   placeholder="请输入手机号码" style="margin-top: 5px;color: #ffffff;"  v-model="tel" clearable/>

                <div style="text-align: left;font-size: 13px;margin-bottom: 5px;margin-top: 10px;">验证码</div>
                <van-field  style="margin-top: 5px;"
                           v-model="verifyCode" type="number"
                           placeholder="请输入验证码">
                    <template #button>
                        <van-count-down
                            v-show="showCountDown"
                            ref="countDown"
                            :time="60000"
                            :auto-start="false"
                            format="重新发送(ss秒)"
                            style="font-size: 12px;color: #888888"
                            @finish="finish"
                        />
                        <span style="color: #7a69be;font-size: 12px;" v-show="!showCountDown" @click="sendSmsForBind">发送验证码</span>
                    </template>
                </van-field>

                <div class="left-top-title">
                    <span>为了账号安全，请及时绑定手机号码</span>
                </div>

                <div class="box-button">
                    <van-button type="info" round block native-type="submit" :loading="loading" style="height: 35px;font-weight: bold;"
                                loading-text="正在绑定" color="#7a69be">
                        立即绑定
                    </van-button>
                </div>


                <div  style="font-size: 12px;margin-top: 15px;display: flex;justify-content: center;padding: 0 3px;cursor: pointer;user-select: none;">
                    <div @click="miss" style="color: #888888">跳过绑定，直接进入游戏</div>
                </div>

            </div>
        </van-form>
    </div>
</template>

<script>

    import {request} from '@/api/request'

    import {Toast} from 'vant'

    export default {
        name: 'bindTel',
        data() {
            return {
                tel: '',
                verifyCode: '',
                telPattern: /^1\d{10}$/,
                codePattern: /^\d{6}$/,
                showCountDown: false,
                showSendText: true,
                disabledSend: false,
                loading: false,
            };
        },
        methods: {
            onSubmit() {
                let ok = this.checkForm();
                if (ok !== 'ok') {
                    Toast(ok);
                    return;
                }

                this.loading = true;
                let options = {
                    url:"/h5/bindPhone",
                    data: {
                        phone: this.tel,
                        checkCode: this.verifyCode,
                    }
                };
                request(options).then((res) => {
                    this.loading = false;
                    if (res.data.success === true) {
                        Toast.success('手机号码绑定成功');
                        let user = JSON.parse(localStorage.getItem('user'));
                        user.phone = res.data.data;
                        localStorage.setItem('user', JSON.stringify(user));
                        this.miss();
                    } else {
                        Toast.fail(res.data.error.message);
                    }
                });
            },
            sendSmsForBind() {
                let ok = this.checkTel();
                if (ok !== 'ok') {
                    Toast(ok);
                    return;
                }
                this.disabledSend = true;
                this.showSendText = false;
                this.showCountDown = true;
                this.$refs.countDown.start();

                let options = {
                    url:"/h5/sendSms",
                    data: {
                        phone: this.tel,
                        type: '3',
                    }
                };
                request(options).then((res) => {
                    if (res.data.success === true) {
                        Toast('验证码发送成功');
                    } else {
                        Toast(res.data.error.message);
                        this.finish();
                    }
                });
            },
            checkTel() {
                if (!this.tel) {
                    return "请输入手机号码";
                }
                if (!this.telPattern.test(this.tel)) {
                    return "请输入正确的手机号码";
                }
                return 'ok';
            },
            checkForm() {
                if (!this.tel) {
                    return "请输入手机号码";
                }
                if (!this.telPattern.test(this.tel)) {
                    return "请输入正确的手机号码";
                }
                if (!this.verifyCode) {
                    return "请输入验证码";
                }
                if (!this.codePattern.test(this.verifyCode)) {
                    return "验证码为6位数字";
                }
                return 'ok';
            },
            finish() {
                this.disabledSend = false;
                this.showSendText = true;
                this.showCountDown = false;
                this.$refs.countDown.reset();
            },
            miss() {
                this.$parent.$parent.showSelectAccount();
            }
        }
    }
</script>

<style scoped>
.main-container {
    padding: 10px 15px;
}

.left-top-title{
    text-align: left;
    color: #888888;
    vertical-align: center;
    font-size: 12px;
    height: 30px;
    margin-top: 7px;
}

.left-top-title h2{
    display: inline-block;
    font-size: 1.3rem;
    margin: 0;
}

.van-cell{
    padding: 6px 16px;
}

.box-button {
    margin: 10px 0 10px;
}
.van-field{
    outline: 1px #dbdbdb solid;
    border-radius: 3px;
}
.van-field:focus-within{
    outline: 2px #7a69be solid;
}
</style>
