<template>
    <div class="main-container">
        <div class="top-info">
            <img src="../../assets/default_header_img.png" style="width: 40px;height: 40px;border-radius: 10px;">

            <div class="title-user-panel">
                <div>
                    <span class="user-name">{{username}}</span>
                    <span class="user-uid">{{accountName}}<span style="margin-left: 10px;">id: {{uid}}</span></span>
                </div>
                <div class="second-info">
                    <span>星元  <span style="font-weight: bold">{{ptb}}</span></span>
                    <span style="margin-left: 15px;">代金券  <span style="font-weight: bold">{{djq}}</span></span>
                </div>
            </div>

            <van-icon @click="continuePlay" name="cross" color="#d0d0d0" size="20" class="close-x"/>
        </div>

        <van-divider :style="{ borderColor: '#d0d0d0', 'font-size': '.8rem', margin: '0 10px' }">
        </van-divider>
        <div class="main-panel">

            <van-cell title="我的实名" :value="realNameInfo" />
            <van-cell title="修改密码" @click="goPasswordPage" is-link />
            <van-cell title="联系客服" @click="goKfPage" is-link />
            <van-cell title="小号管理" @click="goAccountPage" is-link />
            <van-cell title="星元充值" @click="goRechargePage" is-link />
            <van-cell title="福利码兑换" v-if="false" is-link />
        </div>

        <van-divider :style="{ borderColor: '#d0d0d0', 'font-size': '.8rem', margin: '0 0px' }"/>
        <div class="main-bottom-button">

            <div @click="logout" class="main-button" style="color: #888888;background: #ededed;margin-right: 15px;">
                退出登录
            </div>
            <div @click="continuePlay" class="main-button">返回游戏</div>
        </div>

    </div>
</template>

<script>
    import {request, sdkRequest} from '@/api/request'
    import {Dialog, Toast} from 'vant';
    import sdkNotice from '@/api/sdk-notice'

    export default {
        name: "SdkWebview",
        data () {
            return {
                username: '',
                accountName: '',
                uid: '',
                ptb: '',
                djq: '',
                gameId: '',
                exchangeCode: '',
                showJsButton: false,
                userInfo: {},
                realNameInfo: '未实名',
            }
        },
        mounted() {
            this.handleUrl();
            this.initPage();

        },
        methods: {
            handleUrl() {
                this.from = this.$route.query.from;
                let token = this.$route.query.token || '';
                let gameId = this.$route.query.gameId || '';
                this.gameJs = this.$route.query.gameJs || '';
                if (!token) {
                    Toast('当前登录状态已失效，请重新登录游戏');
                    return ;
                }
                if (!gameId) {
                    Toast('游戏参数非法');
                    return ;
                }

                let speed = localStorage.getItem('game_speed') || '1';
                localStorage.clear();

                localStorage.setItem('game_speed', speed);

                localStorage.setItem('from', this.from);
                localStorage.setItem('token', token);
                localStorage.setItem('gameId', gameId);
                localStorage.setItem('gameJs', this.gameJs);
            },
            initPage() {
                let options = {
                    url:"/sdk/h5/getLoginInfo",
                    data: {}
                };
                sdkRequest(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.userInfo = res.data.data;
                        this.ptb = this.userInfo.ptb;
                        this.djq = this.userInfo.djq;
                        this.username = this.userInfo.userName;
                        this.uid = this.userInfo.accountId;
                        this.accountName = this.userInfo.accountName;
                        this.realNameInfo = this.userInfo.realNameInfo && this.userInfo.realNameInfo;

                        localStorage.setItem('userInfo', this.userInfo)
                        localStorage.setItem('userId', this.uid)

                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            continuePlay() {
                sdkNotice.close();
            },
            logout() {
                Dialog.confirm({
                    message: '确认退出登录吗？',
                }).then(() => {
                    sdkNotice.logout();
                }).catch(() => {
                });
            },
            goPasswordPage() {
                this.$router.push("/sdk/password")
            },
            goKfPage() {
                this.$router.push("/sdk/kf")
            },
            goAccountPage() {
                this.$router.push("/sdk/account")
            },
            goRechargePage() {
                this.$router.push("/sdk/recharge")
            },
        }
    }
</script>

<style scoped>
    .main-container {
        width: 300px;
        height: 100vh;
        box-sizing: border-box;
        overflow: hidden;
    }
    .top-info {
        height: 60px;
        text-align: left;
        display: flex;
        padding: 0 15px;
        align-items: center;
        justify-content: left;
        position: relative;
    }

    .close-x {
        position: absolute;
        right: 7px;
        top: 5px;
    }

    .title-user-panel {
        display: inline-block;
        margin-left: .8rem;
        width: 100%;
    }

    .user-name {
        font-size: 12px;
        color: #4d4e50;
        font-weight: bold;
    }

    .user-uid {
        font-size: 12px;
        color: #7a7a7c;
        margin-left: 15px;
    }

    .second-info {
        margin-top: 5px;
        font-size: 12px;
        color: #888888;
    }

    .main-panel {
        text-align: left;
        width: 100%;
        height: calc(100vh - 112px);
        overflow-y: auto;
    }

    .van-cell {
        font-size: .8rem;
    }

    .main-button {
        background: #7a69be;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        font-size: 12px;
        width: 75px;
        font-weight: bold;
        border-radius: 3px;
        cursor: pointer;
        user-select: none;
    }

    .main-bottom-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
    }

    .van-cell {
        padding: 5px 16px;
    }

    .van-field {
        outline: 1px #979696 solid;
        border-radius: 3px;
    }

    /*.van-field:focus-within{
        outline: 1px #3478F6 solid;
    }*/
    /deep/ .van-field__control {
        //color: #3478F6;
    }
</style>