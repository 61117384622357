<template>
    <div class="main-view">
        <home-nav></home-nav>
        <gzh-code-view></gzh-code-view>
        <div style="display: flex;flex-direction: column; width: 100%;min-height: calc(100vh - 380px); max-width: 1200px;margin: 0 auto;">
            <div style="width: 100%;height:70px; background-color: #ffffff;border-radius: 10px;margin-top: 20px;">
                <div style="height: 70px;padding-left: 50px;display: flex;align-items: center;color: #666666">
                    <span>当前位置：</span>
                    <span style="margin-left: 10px;">首页</span>
                    <span style="margin-left: 10px;">></span>
                    <span style="margin-left: 10px;">游戏攻略</span>
                </div>
            </div>

            <div class="common-view">
                <span style="font-size: 18px;color: #888888;">暂无数据</span>
            </div>

        </div>

        <home-footer></home-footer>
    </div>
</template>

<script>
    import homeNav from "@/components/HomeNav";
    import homeFooter from "@/components/HomeFooter";
    import gzhCodeView from "@/components/GzhCodeView";
    import SdkPassword from "@/views/sdk/SdkPassword";

    export default {
        name: "HomeIntro",
        components: {
            SdkPassword,
            homeNav,
            homeFooter,
            gzhCodeView,
        },
        data() {
            return {}
        },
        mounted() {

        },
        methods: {

        }
    }
</script>

<style scoped>
    .main-view {
        min-width: 1400px;
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
        background-color: #eaeef3;
        position: relative;
        -ms-overflow-style: none;  /* IE 和 Edge */
        scrollbar-width: none;/* Firefox */
    }
    .main-view::-webkit-scrollbar {
        display: none;/* 兼容 Chrome, Opera, 和 Safari */
    }
    .common-view {
        width: 100%;
        background-color: #ffffff;
        border-radius: 10px;
        margin-top: 20px;
        height: 100px;
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>